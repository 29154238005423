import { createGlobalStyle } from "styled-components";

import { media } from "constants/media";

export const GlobalStyle = createGlobalStyle`
    html {

        /* ===== Theme colors ====== */

        --color-primaryLight: #4eddb3;
        --color-primary: #22d5a0;
        --color-primaryDark: #1baa80;

        --color-secondaryLight: #4b5983;
        --color-secondary: #1e3064;
        --color-secondaryDark: #182650;

        /* --color-errorLight:
        --color-error:
        --color-errorDark:

        --color-warningLight:
        --color-warning:
        --color-warningDark:

        --color-successLight:
        --color-success:
        --color-successDark:

        --color-dimmedLight:
        --color-dimmed:
        --color-dimmedDark: */

        --color-white: #fff;
        --color-black: #000;

        /* Button */

        --type-button-line-height: 24px;
        --type-button-font-size-small: 13px;
        --type-button-font-size-large: 15px;

        --button-primary-box-shadow: 0 8px 12px rgba(128, 0, 255, 0.17);
        --button-secondary-box-shadow: 0 8px 12px rgba(44, 226, 126, 0.19);

        /* Dropdown */

        --dropdown-box-shadow: 0 76px 80px rgba(34, 12, 74, 0.07),
            0 27.7413px 29.2013px rgba(34, 12, 74, 0.05),
            0 13.4679px 14.1767px rgba(34, 12, 74, 0.04),
            0 6.6022px 6.94968px rgba(34, 12, 74, 0.03),
            0 2.61052px 2.74791px rgba(34, 12, 74, 0.02);

        --pill-box-shadow: 0 2px 31px rgba(34, 12, 74, 0.07),
            0 10.5083px 15.6991px rgba(34, 12, 74, 0.06151,)
            0 5.2525px 7.58201px rgba(34, 12, 74, 0.0528532),
            0 2.47219px 3.48928px rgba(34, 12, 74, 0.0430861),
            0 0.886661px 1.24172px rgba(34, 12, 74, 0.0298631);/* Typography */

        --font-primary: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

        --type-heading-1-font-size: 32px;
        --type-heading-1-line-height: 48px;
        --type-heading-2-font-size: 30px;
        --type-heading-2-line-height: 40px;
        --type-heading-3-font-size: 25px;
        --type-heading-3-line-height: 40px;
        --type-heading-4-font-size: 24px;
        --type-heading-4-line-height: 32px;
        --type-heading-5-font-size: 16px;
        --type-heading-5-line-height: 28px;
        --type-heading-6-font-size: 14px;
        --type-heading-6-line-height: 24px;

        --type-paragraph-font-size: 13px;
        --type-paragraph-line-height: 24px;

        --type-label-font-size: 14px;
        --type-label-line-height: 24px;

        --type-sub-text-font-size-sub-text: 12px;
        --type-sub-text-line-height: 24px;

        /* Smooth scroll behavior for scrolling to ID'ed elements on static pages */

        scroll-behavior: smooth;

        ${media.mdUp} {
            --type-heading-1-font-size: 55px;
            --type-heading-1-line-height: 64px;
            --type-heading-2-font-size: 45px;
            --type-heading-2-line-height: 64px;
            --type-heading-3-font-size: 40px;
            --type-heading-3-line-height: 56px;
            --type-heading-4-font-size: 35px;
            --type-heading-4-line-height: 48px;
            --type-heading-5-font-size: 20px;
            --type-heading-5-line-height: 32px;
            --type-heading-6-font-size: 15px;
            --type-heading-6-line-height: 32px;

            --type-paragraph-font-size: 15px;
            --type-paragraph-line-height: 32px;

            --type-sub-text-font-size-sub-text: 14px;
        }
    }

    * {
        box-sizing: border-box;
    }



    p {
        font-size: var(--type-paragraph-font-size);
        line-height: var(--type-paragraph-line-height);
    }


    /* General components */

    a {
        color: var(--color-violet-50);
        font-size: var(--type-anchor-font-size);
        line-height: var(--type-anchor-line-height);
    }

    button {
        font-size: var(--type-button-font-size-small);
        line-height: var(--type-button-line-height);
    }

    label {
        font-size: var(--type-label-font-size);
        line-height: var(--type-label-line-height);
    }

    /*  General styling */

    html,
    body,
    button,
    input,
    select,
    textarea {
        font-family: var(--font-primary), sans-serif;
    }

    input,
    textarea {
        font-size: var(--font-size---type-paragraph-font-size);
    }

    html {
        background: #F1F9FF;
        color: #27333F;

    }


    html {
        box-sizing: border-box;
    }

    * {
        box-sizing: inherit;

        &:before,
        &:after {
            box-sizing: inherit;
        }
    }

    html,
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin: 0;
        padding: 0;
    }

    /* stylelint-disable selector-max-id */
    #__next {
        overflow-x: hidden;
        height: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
        text-size-adjust: 100%;
    }

`;
