import { GlobalStyle } from "config";

import { Toaster } from "react-hot-toast"
import React from 'react';

import 'tailwindcss/tailwind.css'
import 'components/PlannerLayout/partials/Dialog.css';

function MyApp({ Component, pageProps }) {
    return (
        <>
            <GlobalStyle />
            <Toaster />

            <link rel="stylesheet" href="/global.css" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            
            <Component {...pageProps} />
        </>
    );
}
export default MyApp;
